<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Hydrogen sulfide is commonly associated with the "rotten egg" smell. It is also a highly
        toxic gas that must be dealt with carefully. This compound can decompose by the following
        reaction:
      </p>
      <p class="mb-5 pl-8">
        <chemical-latex content="H2S(g) <=> H2(g) + S(g)" />
        <stemble-latex content="$\qquad \text{K}_\text{p}=$" />
        <number-value :value="KpValue" />
      </p>

      <p class="mb-3">
        If the reaction vessel initially contains
        <chemical-latex content="H2S(g)" />
        at a pressure of
        <number-value :value="pH2S" unit="\text{bar,}" />
        determine the total pressure,
        <stemble-latex content="$\text{P}_\text{tot},$" />
        in the container when the reaction reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.Ptot"
        prepend-text="$\text{P}_\text{tot}:$"
        append-text="$\text{bar}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ResponseFeedback from '../response-utils/ResponseFeedback';
import TaskButtons from '../TaskButtons';
import SubmitButton from '../response-utils/SubmitButton';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question120',
  components: {
    StembleLatex,
    SubmitButton,
    TaskButtons,
    ResponseFeedback,
    ChemicalLatex,
    LatexNumber,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ptot: null,
      },
    };
  },
  computed: {
    pH2S() {
      return this.taskState.getValueBySymbol('pH2S').content;
    },
    KpValue() {
      return this.taskState.getValueBySymbol('KpValue').content;
    },
  },
};
</script>
